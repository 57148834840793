import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import { IAppState } from '../../store/rootDuck';
import Bots from './users/users/Bots';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const OrdersPage = lazy(() => import('./orders'));
const ChatsPages = lazy(() => import('./chats'));
const VersionPages = lazy(() => import('./version'));
const MessagePages = lazy(() => import('./firstMessage'));
const AutoResponsePages = lazy(() => import('./autoResponse'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  isAuthorized,
  setMenuConfig,
  fetchMe,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));

  // for user profile in header
  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          isAuthorized ? (
            <Redirect exact from='/' to='orders/list/full' />
          ) : (
            <Redirect exact from='/' to='auth/main' />
          )
        }

        <Route path='/settings' component={SettingsPage} />

        <Route path='/categories' component={CategoriesPage} />
        <Route path='/products' component={ProductsPage} />
        <Route path='/documents' component={UserDocPage} />
        <Route path='/users' component={UsersPage} />
        <Route path='/orders' component={OrdersPage} />
        <Route path='/chats' component={ChatsPages} />
        <Route path='/version' component={VersionPages} />
        <Route path='/message' component={MessagePages} />
        <Route path='/autoresponse' component={AutoResponsePages} />
        <Route path='/bots' exact component={Bots} />

        <Redirect to='/error/error-v1' />
      </Switch>
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    isAdmin: state.auth.user?.is_admin,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    editMe: profileActions.editRequest,
    setMenuConfig: builder.actions.setMenuConfig,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
