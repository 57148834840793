/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';

import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { API_DOMAIN } from '../../../app/constants';

const useStyles = makeStyles(theme => ({
  headerBtn: {
    marginLeft: theme.spacing(1),
  },
  authText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cartText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

const UserProfile = ({
  me,
  loading,
  showHi,
  showAvatar,
  showBadge,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const userRoles = me?.roles || [];

  if (loading) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {me ? (
        <Dropdown
          className='kt-header__topbar-item kt-header__topbar-item--user'
          drop='down'
          alignRight
        >
          <Dropdown.Toggle as={HeaderDropdownToggle} id='dropdown-toggle-user-profile'>
            <div
              className='kt-header__topbar-user'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {showHi && (
                <span className='kt-header__topbar-username kt-hidden-mobile '>
                  {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' }) +
                    (showHi && me.first_name ? ',' : '')}
                </span>
              )}
              {showHi && me.first_name && (
                <span
                  className='kt-header__topbar-username kt-hidden-mobile'
                  style={{ padding: '0 0.55rem 0 0' }}
                >
                  {me.first_name}
                </span>
              )}

              {showAvatar && (
                <div
                  className='kt-user-card__avatar'
                  style={{
                    borderRadius: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {me.avatar ? (
                    <img
                      style={{ objectFit: 'contain', width: 34, height: 34 }}
                      src={`${API_DOMAIN}/${
                        typeof me.avatar === 'string' ? me.avatar : me.avatar.small
                      }`}
                      alt=''
                    />
                  ) : (
                    <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                      <b>{(me.first_name || '').toUpperCase()}</b>
                    </span>
                  )}
                </div>
              )}

              {showBadge && (
                <span className='kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold'>
                  {me.first_name || ''}
                </span>
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div
              className='kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div
                className='kt-user-card__avatar'
                style={{ overflow: 'hidden', width: 60, height: 60, borderRadius: 4 }}
              >
                {me.avatar ? (
                  <img
                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    src={`${API_DOMAIN}/${
                      typeof me.avatar === 'string' ? me.avatar : me.avatar.small
                    }`}
                    alt=''
                  />
                ) : (
                  <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    <b>{(me.first_name || '').toUpperCase()}</b>
                  </span>
                )}
              </div>
              <div className='kt-user-card__name' style={{ fontWeight: '700' }}>
                {me.first_name || ''}
              </div>
            </div>
            <div className='kt-notification'>
              <Link to='/settings/profile' className='kt-notification__item'>
                <div className='kt-notification__item-icon'>
                  <i className='flaticon2-calendar-3 kt-font-success' />
                </div>
                <div className='kt-notification__item-details'>
                  <div className='kt-notification__item-title' style={{ fontWeight: '700' }}>
                    {intl.formatMessage({ id: 'SUBMENU.PROFILE' })}
                  </div>
                  <div className='kt-notification__item-time' style={{ fontWeight: '600' }}>
                    {intl.formatMessage({ id: 'SUBMENU.ACCOUNT_SETTINGS' })}
                  </div>
                </div>
              </Link>

              <div className='kt-notification__custom'>
                <Link
                  to='/logout'
                  className='btn btn-label-brand btn-sm'
                  style={{ fontWeight: '700' }}
                >
                  {intl.formatMessage({ id: 'SUBMENU.EXIT' })}
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className='kt-login__signup' style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              history.push('/auth');
            }}
            className={classes.headerBtn}
            variant='contained'
            color='secondary'
          >
            {intl.formatMessage({ id: 'SUBMENU.AUTHORIZE' })}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  me: store.profile.me,
  loading: store.profile.loading,
  isAuthorized: store.auth.user != null,
});

export default connect(mapStateToProps)(UserProfile);
