import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const { orders, users, legal, settings, chats } = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [orders, users, settings, legal, chats],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getDefaultMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
