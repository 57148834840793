export const menuConfigItems = {
  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    // icon: 'flaticon-logout',
  },

  orders: {
    root: true,
    title: 'MENU.ORDERS',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    activePages: ['orders/'],
    page: 'orders/list/full',
    submenu: [
      {
        translate: 'SUBMENU.ALLORDERS',
        title: 'SUBMENU.ALLORDERS',
        page: 'orders/list/full',
      },
      {
        translate: 'SUBMENU.ABANDONEDCHECKOUTS',
        title: 'SUBMENU.ABANDONEDCHECKOUTS',
        page: 'orders/list/abandoned',
      },
    ],
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/buyers',
    submenu: [
      {
        translate: 'SUBMENU.CUSTOMERS',
        title: 'SUBMENU.CUSTOMERS',
        page: 'users/buyers',
      },
      { translate: 'SUBMENU.TEAM', title: 'SUBMENU.TEAM', page: 'users/managers' },
      { translate: 'SUBMENU.BOTS', title: 'SUBMENU.BOTS', page: 'bots' },
    ],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    activePages: ['products/types'],
    page: 'products/types/list',
    submenu: [
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/types/list',
        activePages: ['products/types/create'],
      },
      {
        title: 'MENU.PRODUCTS.VIDEO',
        translate: 'MENU.PRODUCTS.VIDEO',
        page: 'products/video',
        activePages: ['products/types/create'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'CATEGORY.FIND.TITLE',
        page: 'categories/find',
        translate: 'CATEGORY.FIND.TITLE',
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
      {
        title: 'MENU.VERSION.APP',
        page: 'version/app',
        translate: 'MENU.VERSION.APP',
      },
      {
        title: 'MENU.FIRST.MESSAGE',
        page: 'message',
        translate: 'MENU.FIRST.MESSAGE',
      },
      {
        title: 'MENU.AUTORESPONSE',
        page: 'autoresponse',
        translate: 'MENU.AUTORESPONSE',
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    activePages: ['documents'],
    page: 'documents/legacy',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.PERSONAL_DATA',
        translate: 'SUBMENU.PERSONAL_DATA',
        page: 'documents/refund',
      },
      {
        title: 'SUBMENU.LEGAL_SHIPPING',
        translate: 'SUBMENU.LEGAL_SHIPPING',
        page: 'documents/shipping',
      },
      {
        title: 'SUBMENU.OFFER_CONTRACT',
        translate: 'SUBMENU.OFFER_CONTRACT',
        page: 'documents/offer_contract',
      },
      {
        title: 'SUBMENU.OFFER_CONTRACT_SERVICE',
        translate: 'SUBMENU.OFFER_CONTRACT_SERVICE',
        page: 'documents/offer_service',
      },
    ],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    // icon: 'flaticon-login',
    page: 'auth',
  },

  chats: {
    root: true,
    title: 'SUBMENU.CHATS',
    translate: 'SUBMENU.CHATS',
    bullet: 'dot',
    page: 'chats',
    activePages: ['chats'],
  },
};
