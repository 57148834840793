import React from 'react'
import UserProfile from '../../partials/layout/UserProfile'
import {createStyles, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    menuContainer: {
      display: 'block',

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu: {
      width: 25,
      height: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  })
);

const Topbar = props => {
  const classes = useStyles();

  return (
    <div
      className='kt-header__topbar'
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <UserProfile showAvatar={true} showHi={true} showBadge={false} />
    </div>
  );
};

export default Topbar;
