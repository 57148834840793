import axios from 'axios';
import {
  delPhotoFromProduct,
  getProductByIdUrl,
  PRODUCT_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
} from '../constants';
import { IProductFilter } from '../interfaces/product';

export const createProduct = (files: FormData) =>
  axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const editProduct = (id: number, files: FormData) =>
  axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteProduct = (id: number | undefined) =>
  axios.delete(getProductByIdUrl(id || -1));

export const getProductById = (id: number) => axios.get(getProductByIdUrl(id));

export const getProducts = ({
  page = 1,
  perPage = 20,
  companyId,
  categoryId,
  filter = {},
}: {
  page: number;
  perPage: number;
  companyId?: number;
  categoryId?: number;
  filter?: Partial<IProductFilter>;
}) => {
  if (companyId) {
    let url = `/api/shop/company/${companyId}/products?page=${page}&per_page=${perPage}`;
    if (categoryId) url += `&category_id=${categoryId}`;
    return axios.get(url);
  }
  if (categoryId) {
    return axios.post(
      `/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}`
    );
  }
  const body = {
    price_from: filter.price_from ? +filter.price_from : null,
    price_to: filter.price_to ? +filter.price_to : null,
  };

  return axios.post(PRODUCTS_URL, body, {
    params: {
      page,
      per_page: perPage,
    },
  });
};

export const delPhoto = (photoId: number) => axios.delete(delPhotoFromProduct(photoId));

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}


