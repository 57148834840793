const {
  REACT_APP_API_DOMAIN = 'butler-api.me-interactive.net',
  // lk-api.butler-vip.ru
  // butler-api.me-interactive.net
  // butler-apidev.me-interactive.net
  PHONE_MASK = '+9 999 999 99 99',
} = process.env;

const protocol = 'https:';
const API_DOMAIN = `${protocol}//${REACT_APP_API_DOMAIN}`;

// const CHAT_DOMAIN = 'https://chat-dev7.me-interactive.net/api/v4';
// const CHAT_SOCKET = 'wss://chat-dev7.me-interactive.net/api/v4/websocket';

const CHAT_DOMAIN = 'https://chat8.magic-egg.net/api/v4';
const CHAT_SOCKET = 'wss://chat8.magic-egg.net/api/v4/websocket';

// const CHAT_DOMAIN = 'https://chat.butler-vip.ru/api/v4';
// const CHAT_SOCKET = 'wss://chat.butler-vip.ru/api/v4/websocket';

// const CHAT_DOMAIN = 'https://chat-dev.me-interactive.net/api/v4';
// const CHAT_SOCKET = 'wss://chat-dev.me-interactive.net/api/v4/websocket';

export { API_DOMAIN, PHONE_MASK, CHAT_DOMAIN, CHAT_SOCKET };

export const LOGIN_URL = 'api/user/login';
export const LOGIN_BY_PHONE = 'api/login/by_phone';
export const SEND_CODE = 'api/login/send_code';
export const REGISTER_URL = 'api/_p/user/register';
export const ME_URL = 'api/me';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const getRecoveryPasswordUrl = (email: string): string =>
  `/api/_p/reset_password/send_code?email=${email}`;
export const NEW_PASSWORD_URL = '/api/_p/change_password_from_link';

export const CREATE_USER_URL = '/api/user/create';
export const getUserUrl = (id: number): string => `/api/user/${id}`;
export const SEND_INVITE_URL = '/api/user_activate/send';
export const delUserUrl = (id: number): string => `/api/user/${id}`;
export const uploadUserAvatarUrl = (id: string): string => `/api/user/${id}/upload_photos`;

export const uploadPhotoUrl = (modificationId: number): string =>
  `/api/modification/${modificationId}/upload_photos`;
export const delPhotoUrl = (id: number): string => `/api/photo/${id}`;
export const setMainPhotoUrl = (id: number): string => `/api/photo/${id}/set_main`;

export const CREATE_CATEGORY_URL = '/api/shop/category';
export const getCategoryUrl = (id: number | undefined) => `/api/shop/category/${id}`;
export const FULL_CATEGORIES_URL = '/api/shop/categories/full';
export const ROOT_CATEGORIES_URL = '/api/shop/categories/roots';
export const getCategoriesWithChildrenUrl = (id: number) =>
  `/api/shop/category/${id}/children`;
export const getCategoriesWithParentUrl = (id: number) => `/api/shop/category/${id}/parents`;
export const getCategoriesPositionsUrl = (positions: string) =>
  `/api/shop/categories/set_positions?positions=${positions}`;

export const PRODUCT_URL = '/api/shop/product';
export const PRODUCTS_URL = '/api/shop/products';
export const getProductByIdUrl = (id: number) => `/api/shop/product/${id}`;
export const getProductsByCategory = (id: number) => `/api/shop/category/${id}/products`;
export const delPhotoFromProduct = (id: number) => `/api/shop/product_photo/${id}`;
export const setProductMainPhotoUrl = (id: number): string =>
  `/api/shop/product_photo/${id}/set_main`;

export const PRODUCT_TYPES_URL = '/api/shop/product_types';
export const getProductTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;
export const PRODUCT_TYPE_URL = '/api/shop/product_type';
export const delProdyctTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;

export const addParameterForType = (id: number) => `/api/shop/product_type/${id}/parameter`;
export const editParameterForType = (id: number) => `/api/shop/product_type_parameter/${id}`;
export const getParametersForType = (id: number) => `/api/shop/product_type/${id}/parameters`;

export const getOrdersByStatus = (statuses: string) => `/api/shop/orders?statuses=${statuses}`;
export const getOrderurl = (id: number) => `/api/shop/order/${id}`;

export const sendSmsUrl = (country_name: string = 'Russia', country_code: string = '7') =>
  `/api/users/code_register_sms?country_name=${country_name}&country_code=${country_code}`;
export const GET_COUNTRY_ID = '/api/phone_codes';

export const deleteParameterForType = (id: number) => `/api/shop/parameter/${id}`;
