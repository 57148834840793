/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';

import BreadCrumbs from './components/BreadCrumbs';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

class SubHeader extends React.Component {
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      userRoles,
    } = this.props;

    return (
      <div id='kt_subheader' className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className='kt-subheader__main'>
            {subheaderMobileToggle && (
              <button
                className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                id='kt_subheader_mobile_toggle'
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({ subheader: { title, breadcrumb, description, back } }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {back && (
                      <IconButton
                        color='inherit'
                        aria-label='previous'
                        onClick={() => this.props.history.goBack()}
                      >
                        <ChevronLeftIcon color='inherit' />
                      </IconButton>
                    )}
                    {breadcrumb && <BreadCrumbs items={breadcrumb} />}
                    {title && description && (
                      <span className='kt-subheader__separator kt-subheader__separator--v' />
                    )}
                    {title && (
                      <h3 className='kt-subheader__title' style={{ fontWeight: 600 }}>
                        {title}
                      </h3>
                    )}
                  </div>
                  {description && <span className='kt-subheader__desc'>{description}</span>}
                </>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  userRoles: store.auth.user?.roles,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
